import { Component, OnInit, Inject } from '@angular/core';
import { QuestionAnswersComponent } from '../question-answers/question-answers.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  description: String,
  title: String
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<QuestionAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,  private router: Router) {}

  ngOnInit(): void {
  }

  onClick() {
    this.dialogRef.close();
    // if(this.router.url=="/pages/memberweb-privilages"){
    //   return this.router.navigate(['pages/home']);
    // }
    // if (this.router.url=="/pages/edit-user") {
    //   for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    //     if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText === "Update User") 
    //        {
    //           (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
    //        }
    //      }
    //      return;
    // }
    // this.router.navigate(['pages/search-user'])
    if (localStorage.getItem("isRequestFromEditScreen") === "Y") {
      this.router.navigate(['pages/search-user'])
    } else if (localStorage.getItem("isRequestFromEditScreen") === "N") {
      this.router.navigate(['pages/create-user']) 
    } else if (localStorage.getItem("isRequestFromCreateScreen") === "Y") {
      this.router.navigate(['pages/multiAppAccess']);
    }
  }
}
