import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { OktaAuthService } from '@okta/okta-angular';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { Memberweb } from 'src/app/model/memeberweb';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { SubMenuList } from 'src/app/model/subMenuList';
import { int } from 'aws-sdk/clients/datapipeline';
import { Router } from '@angular/router';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { MultiAppMenus, RequestParameters } from 'src/app/model/multiAppMenus';
import { HomeAppMenus } from 'src/app/model/homeAppMenus';
import { DatePipe } from '@angular/common';
import * as cloneDeep from 'lodash/cloneDeep';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { GlobalConstants } from '../global-constants';
import { User } from 'src/app/model/user';
import { Profile } from 'src/app/model/profile';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { formatDate } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';

export interface Menu {
  "menu-id": string;
  "sub-menu-id-list": string[];
}
export interface appDetails {
  value: string;
  viewValue: string;
  menuType: string;
  appUrl: string
  description: string;
  group_prefix: string;
}

export interface subItem {
  id: number;
  name: string;
  checked: boolean;
}

export interface RootObject {
  menus: Menu[];
  "updated-by": string;
}
export interface groups{
  id: string;
  name: string;
  desc: string;
}
export interface Items {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-multi-app-access',
  templateUrl: './multi-app-access.component.html',
  styleUrls: ['./multi-app-access.component.css']
})

export class MultiAppAccessComponent implements OnInit {
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  appDetails: appDetails[] =[];
  master_checked: boolean[] = new Array();
  accessToken: string;
  disableCheckboxes:boolean = false;
  appMenuItems: MultiAppMenus[]=[] ;
  homemenuItems: HomeAppMenus[]=[];
  menuitems: Memberweb = new Memberweb();
  tempmenuitems: Memberweb = new Memberweb();
  mwMenuItems: MemberwebMenuItems[];
  headings = [];
  appName: string;
  DisplayName: string;
  Discription: string;
  qkMenuItem: string;
  atcRole: string;
  sysDate : string;
  menus;
  tempMenu;
  isRadio: boolean = false;
  isNestedCheckbox: boolean = false;
  isSubCheckbox: boolean = false;
  showButtons: boolean = true;
  ediObj: any;
  isPropertyUser: boolean;
  userOktaId: string;
  groups: groups[] = [];
  appMenuItem: boolean = false;
  highlightedButton: string;
  changesMade: boolean = false;
  changesSaved: boolean = false;
  permissionList: MultiAppMenus[]=[];
  rolesList: MultiAppMenus[]=[];
  isRadioCheckbox: boolean = false;
  isAtcApp: boolean =false;
  memberId: string = '';
  memberIdControl = new FormControl('',[Validators.pattern("^[0-9]{3}")]);
  selectedGroupId: Items[] = [];
  qkIdControl = new FormControl('');
  isSuperEmployee: boolean;
  group_prefix: string;

  buttonClicked(name){
    localStorage.setItem("clickedButton", name.viewValue);
    this.toggleHighlight();
    this.getSelectAppMenu(name.value,name.menuType, name.viewValue, name.description, name.group_prefix);
  }

  toggleHighlight(){
    this.highlightedButton =  localStorage.getItem("clickedButton");
  }

  constructor(public oktaAuth: OktaAuthService, private dataService: DataService, public dialog: MatDialog, private router: Router,
    private globalErrorHandlerService:GlobalErrorHandlerService, private datePipe: DatePipe, public globalConstants:GlobalConstants) { }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    this.dataService.getInvokeEvent('Create User - '+this.dataService.createUser.profile.firstName +' '+this.dataService.createUser.profile.lastName);
    var date = new Date();
    this.sysDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    if (this.dataService.userclaims.idm_role[0] == 'OH-IDM-Role User Management - Restricted'){
      this.disableCheckboxes = true;
    }
    this.isPropertyUser = this.dataService.createUser.profile.employeeTypeIDM.includes("PROPERTY");
    this.dataService.getCurrentUserDeatils(this.dataService.createUser.profile.login, this.accessToken, this.dataService.userclaims.userId).then(data => {
      data = JSON.parse(data);
      this.userOktaId = data.id;
    });
    this.dataService.canShowAutoclerk = false;
    let reqParams : RequestParameters = {
      "token": this.accessToken,
      "submittedBy": this.dataService.userclaims.userId,
      "userType": this.dataService.createUser.profile.employeeTypeIDM,
      "userId": this.dataService.createUser.profile.uid
    }
    this.dataService.getMultiappDetails(reqParams).subscribe(
      (response) => {
        let data = JSON.parse(JSON.stringify(response.body));
        for (var i = 0; i < response.body.length; i++){
          this.appDetails.push({value: data[i].sk, viewValue: data[i].name, menuType: data[i].menuType, appUrl: data[i].endpoint,
             description: data[i].description, group_prefix: data[i].groupPrefix});
        }
        localStorage.setItem("clickedButton", 'Member Web');
        this.toggleHighlight();
        this.getSelectAppMenu('MemberWeb','isSubCheckbox', 'Member Web', 'Note: Please use save button on each application screen. Unsaved changes will be lost',null);
     },
      (error) => {
        console.log(error);
        this.globalErrorHandlerService.handleError(error);
      }
    );
  }

  Clear() {
    if(this.appName == 'MemberWeb' && this.isSubCheckbox){
      for (var i = 0; i < Object.keys(this.mwMenuItems).length; i++) {
        this.subMenuAllunChecked(i)
        this.master_change(this.headings[i].name, i)
      }
    } else if (this.isRadio){
      this.qkMenuItem = null;
    }  else if(this.isNestedCheckbox) {
      this.homemenuItems.forEach(menu => menu.children.forEach(subMenu => subMenu.selectedOption = 'No Access'));
    }  else if (this.isRadioCheckbox) {
      this.disableCheckboxes = false;
      this.qkMenuItem = null;
      for (var i = 0; i < this.permissionList.length; i++) {
        this.permissionList[i].selected = false;
      }
    }
  }

  subMenuAllchecked(val: int) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: int) {
    this.master_checked[val] = false;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let user_id = this.dataService.userclaims.userId;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.mwMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.mwMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }
        this.menuitems['updated-by'] = user_id;
      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.menuitems.menus[order] = null;
    }
    JSON.stringify(this.menuitems);
  }

  master_change(str: string, val: int) {
    for (let value of this.mwMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  cancel() {
    this.dataService.isCancelclicked = true;
    this.router.navigate(['pages/create-user']);
  }

  getSelectAppMenu(appCode, menutype, displayName, description, group_prefix) {
    this.mwMenuItems = null;
    this.appMenuItems = null;
    this.homemenuItems = null;
    this.Discription = null;
    this.group_prefix = null;
    this.isAtcApp = false;
    if (menutype == 'radio'){
      this.isRadio = true;
      this.isSubCheckbox = false;
      this.isNestedCheckbox = false;
      this.isRadioCheckbox = false;
    } else if(menutype == 'isSubCheckbox') {
      this.isSubCheckbox = true;
      this.isRadio = false;
      this.isNestedCheckbox = false;
      this.isRadioCheckbox = false;
    } else if(menutype == 'nestedCheckbox'){
      this.isSubCheckbox = false;
      this.isRadio = false;
      this.isNestedCheckbox = true;
      this.isRadioCheckbox = false;
    } else if(menutype == 'radioCheckbox') {
      this.isSubCheckbox = false;
      this.isRadio = false;
      this.isNestedCheckbox = false;
      this.isRadioCheckbox = true;
    }
    if(this.isRadioCheckbox && appCode == 'Autoclerk'){
      this.isAtcApp = true;
    }
    let appId: string = 'APP-' + appCode;
    this.appName = appCode;
    this.Discription = description;
    this.DisplayName = displayName.toUpperCase();
    this.group_prefix = group_prefix;
    this.dataService.memberweb_menu_url =  this.appDetails.find(element => {
      return element.value === appCode;
    }).appUrl;
    if (appCode== 'MemberWeb') {
      if (this.dataService.userclaims.idm_role[0] == 'OH-IDM-Role User Management - Restricted'){
        this.disableCheckboxes = true;
      }
      if (this.dataService.isSetFromTemplate == true) {
        if(!this.dataService.canAccessMemberwebTab) {
          this.disableCheckboxes = true;
        }
        this.dataService.getMemberMenus(this.accessToken).subscribe(
          (response) => {
            this.headings = response
            this.mwMenuItems = JSON.parse(JSON.stringify(response));
            this.getUserSpecificMenus();
          },
          (error) => {
            console.log(error);
            this.globalErrorHandlerService.handleError(error);
          }
        );
      } else {
        if(!this.dataService.canAccessMemberwebTab) {
          this.disableCheckboxes = true;
        }
        this.dataService.getMemberMenus(this.accessToken).subscribe(
          (response) => {
            this.headings = response
            this.mwMenuItems = JSON.parse(JSON.stringify(response));
            for (var i = 0; i < Object.keys(this.mwMenuItems).length; i++) {
              this.subMenuAllunChecked(i)
              this.master_change(this.headings[i].name, i)
            }
            this.dataService.getMemberOnePageUser(this.dataService.createUser.profile.uid, this.accessToken).subscribe(
              (response) => {
                this.menus = response;
                this.tempMenu = cloneDeep(this.menus);
                this.setMenu(true);
              },
              (error) => {
                console.log(error);
              }
            );
            if ((this.menus == null || typeof this.menus == 'undefined') && this.dataService.canAccessMemberwebTab && this.dataService.createUserType != 'PROPERTY_STAFF') {
              for (var i = 0; i < Object.keys(this.mwMenuItems).length; i++) {
                for (let value of this.mwMenuItems[i]['sub-menu-list']) {
                  value.checked = true;
                  this.master_checked[i] = true;
                }
                this.list_change(this.mwMenuItems[i].name, i);
              }
            }
          },
          (error) => {
            console.log(error);
            this.globalErrorHandlerService.handleError(error);
          }
        );
      }
    } else {
      let reqParams : RequestParameters = {
        "appCode": appCode,
        "menuType": menutype,
        "token": this.accessToken,
        "submittedBy": this.dataService.userclaims.userId,
        "userType": this.dataService.createUser.profile.employeeTypeIDM,
        "userId": this.dataService.createUser.profile.uid
      }

      this.dataService.getMultiappMenus(reqParams).subscribe(
        (response) => {
          if(this.isNestedCheckbox){
            this.homemenuItems = JSON.parse(JSON.stringify(response.body));
            this.homemenuItems.forEach(menu => menu.children.forEach(subMenu => subMenu.selectedOption = 'No Access'));
          } else if(this.isRadioCheckbox){
            this.rolesList = JSON.parse(JSON.stringify(response.body.roles))
            this.permissionList = JSON.parse(JSON.stringify(response.body.permissions));
            this.rolesList.splice(0,0,({
              OWNER: "",
              INSERTDATE: null,
              ENDPOINT: "",
              SK: "MENU",
              PK: "DEFAULT",
              GROUP: "",
              GROUPID: "",
              UPDATEDATE: null,
              TYPE: "",
              NAME: "No Access",
              PARENT: "",
              selected:true
            }))
          } else {
            this.appMenuItems = JSON.parse(JSON.stringify(response.body));
          }
          if (this.appMenuItems){
            if(this.isRadio){
              this.appMenuItems.splice(0,0,({
                OWNER: "",
                INSERTDATE: null,
                ENDPOINT: "",
                SK: "MENU",
                PK: "DEFAULT",
                GROUP: "",
                GROUPID: "",
                UPDATEDATE: null,
                TYPE: "",
                NAME: "No Access",
                PARENT: "",
                selected:true
              }))
              this.qkMenuItem = 'No Access'
            }
          }
          this.dataService.getUserGroupDetails(this.dataService.createUser.profile.login,this.accessToken,this.group_prefix).subscribe(
            (response) => {
              this.groups = JSON.parse(JSON.stringify(response));
              this.groups.forEach(element => {
                if(this.appMenuItems != null && typeof (this.appMenuItems) != 'undefined'){
                  for (var i = 0; i < this.appMenuItems.length; i++) {
                    if(this.appMenuItems[i].GROUPID == element.id){
                      console.log("App menu items: ", this.appMenuItems[i])
                      this.qkMenuItem = this.appMenuItems[i].NAME;
                      this.appMenuItems[i].selected = true;
                    }
                 }
                } else if (this.homemenuItems != null && typeof (this.homemenuItems) != 'undefined'){
                  this.homemenuItems.forEach(menu => {
                    menu.children.forEach(subMenu => {
                      const matchedMenu = subMenu.children.find(permission => permission.groupId === element.id);
                      if(matchedMenu) {
                        subMenu.selectedOption = matchedMenu.name;
                      }
                    })
                  })
                } else if (this.isRadioCheckbox){
                  if (appCode == 'Autoclerk')
                  this.memberIdControl.setValue(this.dataService.frontdeskId);
                  for (var i=0; i< this.rolesList.length; i++){
                    if(this.rolesList[i].GROUPID == element.id){
                      this.qkMenuItem = this.rolesList[i].NAME;
                      this.rolesList[i].selected = true;
                      this.atcRole = this.rolesList[i].NAME;
                    }
                  }
                  for(var j=0; j< this.permissionList.length; j++){
                    if(this.permissionList[j].GROUPID == element.id){
                      this.permissionList[j].selected = true;
                    }
                  }
                }
              });
            }
          );
        },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        }
      );
    }
  }

  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async updateMenuAccess() {
    this.selectedGroupId = [];
    var menuData = [];
    if(this.isRadio) {
      for (var i = 0; i < this.appMenuItems.length; i++) {
        if (this.appMenuItems[i].NAME == this.qkMenuItem) {
          this.selectedGroupId.push({value:this.appMenuItems[i].GROUPID, viewValue:this.appMenuItems[i].GROUP});
            menuData.push({
              "SK":this.appMenuItems[i].SK,
              "PK":this.appMenuItems[i].PK
            });
        }
      }
    } else if(this.isNestedCheckbox) {
      this.homemenuItems.forEach(menu => {
        menu.children.forEach(subMenu => {
          if(subMenu.selectedOption !== 'No Access') {
            let selectedPermission = subMenu.children.find(p => p.name === subMenu.selectedOption);
            if(selectedPermission) {
              this.selectedGroupId.push({value:selectedPermission.groupId, viewValue:selectedPermission.group});
              menuData.push({
                "SK":selectedPermission.sk,
                "PK":selectedPermission.pk
              });
            }
          }
        });
      });
    }  else if (this.isRadioCheckbox) {
      for (var i = 0; i < this.rolesList.length; i++) {
        if (this.rolesList[i].NAME == this.qkMenuItem) {
          this.selectedGroupId.push({value:this.rolesList[i].GROUPID, viewValue:this.rolesList[i].GROUP});
            menuData.push({
              "SK":this.rolesList[i].SK,
              "PK":this.rolesList[i].PK
            });
        }
      }
      for (var i = 0; i < this.permissionList.length; i++) {
        if (this.permissionList[i].selected) {
          this.selectedGroupId.push({value:this.permissionList[i].GROUPID, viewValue:this.permissionList[i].GROUP});
            menuData.push({
              "SK":this.permissionList[i].SK,
              "PK":this.permissionList[i].PK
            });
        }
      }
    }
    const menuReq = {
      "submitted_by" :this.dataService.userclaims.userId,
      "bearer_token" : this.accessToken,
      "sel_menus" : menuData
    }
    if(this.isRadio || this.isRadioCheckbox || this.isNestedCheckbox) {
      if((this.isRadio || this.isRadioCheckbox || this.isNestedCheckbox) && this.appName != 'Autoclerk'){
        this.dataService.getMenuRestriction(this.dataService.createUser.profile.uid,menuReq,this.accessToken).subscribe(
          (response) => {
            let data = JSON.parse(JSON.stringify(response.body));
            if(data.decision == 'Allow'){
              this.updateGroupsToOkta(this.selectedGroupId,this.group_prefix);
            } else {
              this.openDialog("You are not permitted to grant one or more of these roles/permissions.","Warning!")
            }
          },
          (error) => {
            console.log(error);
          });
      } else if (this.isRadioCheckbox && this.appName == 'Autoclerk' && this.isPropertyUser){
        if (this.memberIdControl.touched && !this.memberIdControl.errors){
          this.dataService.frontdeskId = this.memberId
          let editUser: User = new User();
          let profile: Profile = new Profile();
          console.log(this.dataService.createUser.profile);
          editUser.profile = profile;
          profile.uid = this.dataService.createUser.profile.uid;
          profile.employeeTypeIDM = this.dataService.createUser.profile.employeeTypeIDM;
          profile.beginDate = formatDate(this.dataService.createUser.profile.beginDate, this.dateTimeFormat, this.locale);
          profile.frontDeskIncentives = this.memberId;
          profile.propID = this.dataService.createUser.profile.propID;
          let reqBody: CreateUpdateRequest = new CreateUpdateRequest();
          reqBody.user = editUser;
          reqBody.submitted_by = this.dataService.userclaims.userId;
          reqBody.bearer_token = this.accessToken;
          reqBody.searchUser = this.dataService.createUser.profile.login;
          const body = {
            "Detail": JSON.stringify(reqBody),
            "Source": "updateUserEvent"
          }
          this.dataService.postUserDetails(body,this.accessToken).then((data: String) => {
          },
          (error) => {
            console.log(error);
            let failMeassage = 'Unable to update Frontdesk incentive Id.';
            this.openDialog(failMeassage,"Warning")
          });
        }
        if (!this.memberIdControl.errors){
          this.dataService.getMenuRestriction(this.dataService.createUser.profile.uid,menuReq,this.accessToken).subscribe(
            (response) => {
              let data = JSON.parse(JSON.stringify(response.body));
              if(data.decision == 'Allow'){
                this.updateGroupsToOkta(this.selectedGroupId,this.group_prefix);
              } else {
                this.openDialog("You are not permitted to grant one or more of these roles/permissions.","Warning!")
              }
            },
            (error) => {
              console.log(error);
            });
        }
      }
    }
  }

  async updateMemberwebPrivilages() {
    if (this.appName == 'MemberWeb'){
      if(this.dataService.canAccessMemberwebTab) {
        this.menuitems["updated-by"] = this.dataService.userclaims.userId;
        this.menuitems["bearer_token"] = this.accessToken;
        this.menuitems.menus = JSON.parse(JSON.stringify(this.menuitems.menus).replace(/null,/g, '').replace(/,null/g, ''));
        setTimeout(() => this.dataService.getMemberMenusAccess(this.dataService.createUser.profile.uid, JSON.stringify(this.menuitems), this.accessToken).subscribe(
          (response) => {
            let successMessage = "Menu access provided successfully.";
            this.openDialog(successMessage,"Success")
          },
          (error) => {
            let failMeassage = 'Menu Access Failed!';
            this.openDialog(failMeassage,"Warning")
            console.log(error)
          }
        ), 1000);
      }
    }
  }

  setMenu(flag) {
    if (flag) {
      this.menus = cloneDeep(this.tempMenu)
    }
    this.menus.menus.forEach(element => {
      if (this.mwMenuItems) {
        for (let i = 0; i < Object.keys(this.mwMenuItems).length; i++) {
          if (element['menu-id'] == this.mwMenuItems[i]["menu-id"] && element.hasOwnProperty('sub-menu-id-list')) {
            this.menuitems.menus[i] = element;
            let k = 0;
            for (let j = 0; j < this.mwMenuItems[i]["sub-menu-list"].length; j++) {
              if (element['sub-menu-id-list'].includes(this.mwMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
                this.mwMenuItems[i]["sub-menu-list"][j].checked = true;
                k++;
                if (k == this.mwMenuItems[i]["sub-menu-list"].length) {
                  this.subMenuAllchecked(i);
                }
              }
            }
          }
        }
      }
    });
  }

  tabClick(event: MatTabChangeEvent) {
    if (event.tab.textLabel === "Application Access") {
      this.showButtons = true;
    } else {
      this.showButtons = false;
    }
  }

  backToCreateUser(){
    this.dataService.getInvokeEvent(this.globalConstants.idm);
    localStorage.setItem("isButtonClicked", "Y");
    this.router.navigate(['pages/create-user']);
  }

  getUserSpecificMenus() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    console.log('editobject', editObject);
    this.tempmenuitems.menus = editObject.menuitems.menus;
    for (let i = 0; i < this.mwMenuItems.length; i++) {
      for (let m = 0; m < this.tempmenuitems.menus.length; m++) {
        if (this.tempmenuitems.menus[m] !== null && this.tempmenuitems.menus[m]['menu-id'] == this.mwMenuItems[i]["menu-id"]) {
          let k = 0;
          for (let j = 0; j < this.mwMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.tempmenuitems.menus[m]['sub-menu-id-list'].includes(this.mwMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.mwMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.mwMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);
              }
            }
          }
        }
      }
      this.list_change(this.mwMenuItems[i].name, i);
    }
  }

  radioChange(menuName, appName) {
    this.qkIdControl.reset();
  }

  updateGroupsToOkta(selectedGroupId,group_prefix){
    const selReqBody = {
      "userId": this.userOktaId,
      "loginId": this.dataService.createUser.profile.login,
      "groupId" : selectedGroupId,
      "groupPrefix" :group_prefix,
      "submitted_by" :this.dataService.userclaims.userId,
      "bearer_token" : this.accessToken
    }
    this.dataService.changeGroupAccess(selReqBody,this.accessToken).subscribe(
      (response) => {
        console.log("Access group has been added to okta");
        let successMessage = "Menu access provided successfully.";
        this.openDialog(successMessage,"Success");
      },
      (error) => {
        let failMeassage = 'Menu Access Failed!';
        this.openDialog(failMeassage,"Warning")
        console.log(error)
      });
  }
}
