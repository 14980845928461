<body>
    <div calss="content">
        <mat-tab-group (selectedTabChange)="tabClick($event)" selectedIndex="1">
            <mat-tab disabled>
                <ng-template mat-tab-label>
                    <button mat-raised-button class="save-button" (click)="backToCreateUser()"><i class="back-button material-icons" >arrow_back</i>Back</button>
                </ng-template>
            </mat-tab>
            <mat-tab label="Application Access">
                <mat-sidenav-container>
                    <mat-sidenav mode="side" class="side-nav-list" opened>
                        <mat-nav-list>
                            <button *ngFor= "let name of appDetails" mat-raised-button class="menu-button"
                            (click)="buttonClicked(name)" [class.highlighted]="highlightedButton === name.viewValue">{{name.viewValue}}</button>
                        </mat-nav-list>
                    </mat-sidenav>
                    <mat-sidenav-content>
                        <div *ngIf = "isSubCheckbox || isNestedCheckbox">
                            <mat-card>
                                <div class="two_col_right_save_cancel">
                                    <div class="warning-box">
                                        <!-- Note: Please use save button on each application screen. Unsaved changes will be lost.   -->
                                        {{ this.Discription}}
                                    </div>
                                </div>
                                <mat-card-title class="title-text"><b><u>{{this.DisplayName}}</u></b></mat-card-title><br>
                                <mat-card-content>
                                    <div *ngIf = "isSubCheckbox" class="masonry">
                                        <div class="item" *ngFor="let menuItem of mwMenuItems; let i = index">
                                            <mat-card>
                                                <mat-card-title><b>{{menuItem.name}}</b></mat-card-title>
                                                <mat-card-content>
                                                    <mat-list>
                                                        <mat-list-item>
                                                            <mat-checkbox [(ngModel)]="master_checked[i]"
                                                                (change)="master_change(menuItem.name, i)" [disabled]="disableCheckboxes">
                                                                Assign All {{menuItem.name}}
                                                            </mat-checkbox>
                                                        </mat-list-item>
                                                        <mat-list-item class="pad" *ngFor="let subItem of menuItem['sub-menu-list']">
                                                            <mat-checkbox [(ngModel)]="subItem.checked" [disabled]="disableCheckboxes"
                                                                [labelPosition]="subItem.labelPosition" (change)="list_change(menuItem.name, i)">
                                                                {{ subItem.name }}</mat-checkbox>
                                                        </mat-list-item>
                                                    </mat-list>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </div>
                                    <div *ngIf = "isNestedCheckbox" class="masonry">
                                        <div class="item" *ngFor="let menuItem of homemenuItems; let i = index">
                                            <mat-card>
                                                <mat-card-title><b>{{menuItem.name}}</b></mat-card-title>
                                                <mat-card-content>
                                                    <mat-list class="menu-list">
                                                        <mat-list-item *ngFor="let subItem of menuItem.children">
                                                            <mat-list class="pad">
                                                                <mat-list-item><b>{{ subItem.name }}</b></mat-list-item>                                                
                                                                <mat-list-item>
                                                                    <mat-radio-group class="home_menus" aria-label="select an option for {{ subItem.name }}" [(ngModel)]="subItem.selectedOption">
                                                                        <mat-list class="pad">
                                                                            <mat-list-item *ngFor="let item of subItem.children">
                                                                                <mat-radio-button class="padding-left" [value]="item.name">
                                                                                    {{item.name}}
                                                                                </mat-radio-button>
                                                                            </mat-list-item>
                                                                        </mat-list>
                                                                    </mat-radio-group>
                                                                </mat-list-item>
                                                            </mat-list>
                                                        </mat-list-item>
                                                    </mat-list>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div *ngIf ="isRadio" class="quickSightContent">
                            <mat-card class="box-size">
                                <div class="two_col_right_save_cancel">
                                    <div class="warning-box">
                                        <!-- Note: Please use save button on each application screen. Unsaved changes will be lost.   -->
                                        {{ this.Discription}}
                                    </div>
                                </div>
                                <mat-card class="custom-box">
                                    <mat-card-title class="title-text"><b><u>{{this.DisplayName}}</u></b></mat-card-title>
                                    <mat-card-content>
                                        <div *ngIf = "(isPropertyUser && this.appName == 'QuickSight'); else notPropertyQuicksight">
                                            <mat-radio-group class="qk_menus" [(ngModel)]="qkMenuItem">
                                                <mat-radio-button class="padding-left" [value]="appMenuItems[0].NAME"
                                                (change)="radioChange(appMenuItems[0].NAME, this.appName)" [checked]="appMenuItems[0].selected">
                                                    {{appMenuItems[0].NAME}}
                                                </mat-radio-button>
                                                <mat-radio-button class="padding-left" [value]="appMenuItems[1].NAME"
                                                (change)="radioChange(appMenuItems[1].NAME, this.appName)" [checked]="appMenuItems[1].selected">
                                                    {{appMenuItems[1].NAME}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <ng-template #notPropertyQuicksight>
                                            <div>
                                                <mat-radio-group class="qk_menus" [(ngModel)]="qkMenuItem">
                                                    <mat-radio-button class="padding-left" *ngFor="let menuItem of appMenuItems" [value]="menuItem.NAME"
                                                    (change)="radioChange(menuItem.NAME,this.appName)" [checked]="menuItem.selected">
                                                        {{menuItem.NAME}}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </ng-template>
                                    </mat-card-content>
                                </mat-card>
                            </mat-card>
                        </div><br>
                        <div *ngIf ="isRadioCheckbox" class="quickSightContent">
                            <mat-card class="box-size">
                                <div class="two_col_right_save_cancel">
                                    <div class="warning-box">
                                        {{ this.Discription}}
                                    </div>
                                </div>
                                <mat-card-title class="title-text"><b><u>{{this.DisplayName}}</u></b></mat-card-title>
                                <div *ngIf="this.isAtcApp" class="left_field">
                                    <mat-form-field>
                                        <mat-label style="color:grey; font-weight: 600;">Front Desk Incentive Code</mat-label>
                                        <input matInput [(ngModel)]="memberId" maxlength="3" [formControl]="memberIdControl" placeholder="Please enter 3 digit Front Desk Incentive ID">
                                        <mat-error *ngIf="memberIdControl.hasError('pattern')">{{globalConstants.invalid_memberId}}</mat-error>
                                    </mat-form-field>
                                </div><br>
                                <mat-card class="custom-box">
                                    <mat-card-content>
                                        <div class="sub-heading">Roles:</div>
                                        <div>
                                            <mat-radio-group class="qk_menus" [(ngModel)]="qkMenuItem">
                                                <mat-radio-button class="padding-left" *ngFor="let menuItem of rolesList" [value]="menuItem.NAME" ngDefaultControl
                                                (change)="radioChange(menuItem.NAME, this.appName)" [checked]="menuItem.selected" [formControl]="qkIdControl">
                                                    {{menuItem.NAME}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </mat-card-content>
                                    <mat-error *ngIf="qkIdControl.hasError('invalid')">{{globalConstants.invalid_role}}</mat-error>
                                </mat-card>
                            </mat-card>  
                            <div *ngIf="permissionList.length > 0">
                                <mat-card class="below-box">
                                    <mat-card class="box-below">
                                        <div class="sub-heading">Permissions:</div>
                                        <mat-list >
                                            <mat-list-item style="padding-left:25px" *ngFor="let item of permissionList">
                                                <mat-checkbox [(ngModel)]="item.selected" [disabled]="disableCheckboxes">
                                                    {{item.NAME}}
                                                </mat-checkbox>
                                            </mat-list-item>
                                        </mat-list>
                                    </mat-card>                                   
                                </mat-card>
                            </div>
                        </div><br>
                        <div *ngIf = "isSubCheckbox && showButtons" class="two_col_right_save_cancel">
                            <button class="save-button bg-color-white" mat-raised-button (click)="Clear()">Clear</button>
                            <button class="save-button bg-color-white" mat-raised-button
                                (click)="cancel()">Cancel</button>
                            <button class="save-button" mat-raised-button
                                (click)="updateMemberwebPrivilages()">Save</button>
                        </div>
                        <div *ngIf = "!isSubCheckbox && showButtons" class="two_col_right_save_cancel_qk">
                            <button class="save-button bg-color-white" mat-raised-button (click)="Clear()">Clear</button>
                            <button class="save-button bg-color-white" mat-raised-button
                                (click)="cancel()">Cancel</button>
                            <button class="save-button" mat-raised-button (click)="updateMenuAccess()">Save</button>
                        </div>
                    </mat-sidenav-content>
                </mat-sidenav-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</body>