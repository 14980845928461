import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ToastrService } from 'ngx-toastr';
import * as bcrypt from 'bcryptjs';
import questions from '../.././questions.json';
import { GlobalConstants } from '../global-constants';

export interface Items {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  selectedLanguage: string;
  selectedQuestion1: string;
  selectedQuestion2: string;
  selectedQuestion3: string;
  oktaStoredAnswer1: string;
  oktaStoredAnswer2: string;
  oktaStoredAnswer3: string;
  hashedValue;
  challengeAnswerTemp1
  challengeAnswerTemp2
  challengeAnswerTemp3
  varType1 = "password";
  varType2 = "password";
  varType3 = "password"
  pattern1 = false;
  pattern2 = false;
  pattern3 = false;
  toggle = true;
  isValid = true;
  flag1 = true;
  flag2 = true;
  flag3 = true;
  flag4 = true;
  flag5 = true;
  flag6 = true;
  flag7 = true;
  userdData
  flagVal1 = false;
  flagVal2 = false;
  flagVal3 = false;
  tempData;
  userIdcontrol = new FormControl('', [Validators.required]);
  employeecontrol = new FormControl('', [Validators.required]);
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  initials = new FormControl('', [Validators.required]);
  language = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required]);
  vendorCode = new FormControl('', [Validators.required]);
  //secondaryEmail = new FormControl('', [Validators.required]);
  challengeQuestion1 = new FormControl('', [Validators.required]);
  challengeQuestion2 = new FormControl('', [Validators.required]);
  challengeQuestion3 = new FormControl('', [Validators.required]);
  challengeAnswer1 = new FormControl('', [Validators.required]);
  challengeAnswer2 = new FormControl('', [Validators.required]);
  challengeAnswer3 = new FormControl('', [Validators.required]);
  isChallengeAnswerPolicy: boolean = false;
  login = new FormControl('');
  languagelist: Items[] = [
    { value: 'en', viewValue: 'English' },
    { value: 'de', viewValue: 'German' },
    { value: 'el', viewValue: 'Greek' },
    { value: 'es', viewValue: 'Spanish' },
    { value: 'sv', viewValue: 'Swedish' },
    { value: 'it', viewValue: 'French' },
    { value: 'nl', viewValue: 'Dutch' },
    { value: 'no', viewValue: 'Norwegian' },
    { value: 'pt', viewValue: 'Portugese' },
    { value: 'da', viewValue: 'Danish' },
    { value: 'fi', viewValue: 'Finnish' }
  ];
  hasError=false;
  challengeQuestionsList: Items[] = questions;

  constructor(public oktaAuth: OktaAuthService, private http: HttpClient, private dataService: DataService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService,public globalConstants:GlobalConstants) {

  }
  
  async ngOnInit() {
    const userRole = JSON.parse(localStorage.getItem('userClaims')).userRole;
    if(userRole == 'EMPLOYEE'){
      this.router.navigate(['pages/home']);
    }
    const accessToken = await this.oktaAuth.getAccessToken();
    this.getCurrUserDetails(accessToken);
    this.challengeAnswer1.valueChanges.subscribe(data => {
      if (!this.flag1) {
        this.isValid = false;

      }
      this.flag1 = false;
    })
    this.challengeAnswer2.valueChanges.subscribe(data => {
      if (!this.flag2) {
        this.isValid = false;

      }
      this.flag2 = false;
    })
    this.challengeAnswer3.valueChanges.subscribe(data => {
      if (!this.flag3) {
        this.isValid = false;

      }
      this.flag3 = false;
    })
    this.challengeQuestion1.valueChanges.subscribe(data => {
      if (!this.flag4) {
        this.isValid = false;

      }
      this.flag4 = false;
    })
    this.challengeQuestion2.valueChanges.subscribe(data => {
      if (!this.flag5) {
        this.isValid = false;

      }
      this.flag5 = false;
    })
    this.challengeQuestion3.valueChanges.subscribe(data => {
      if (!this.flag6) {
        this.isValid = false;

      }
      this.flag6 = false;
    })
    this.email.valueChanges.subscribe(data => {
      if (!this.flag7) {
        this.isValid = false;

      }
      this.flag7 = false;
    })
  }

  changeVal() {

    switch ((<HTMLInputElement>event.target).dataset.placeholder) {
      case 'Challenge Answer1':
        if (!this.flagVal1) {
          this.challengeAnswer1.setValue('');
          this.flagVal1 = true;
        }
        this.varType1 = "text";
        break;

      case 'Challenge Answer2':
        if (!this.flagVal2) {
          this.challengeAnswer2.setValue('');
          this.flagVal2 = true;
        }
        this.varType2 = "text";
        break;

      case 'Challenge Answer3':
        if (!this.flagVal3) {
          this.challengeAnswer3.setValue('');
          this.flagVal3 = true;
        }
        this.varType3 = "text";
        break;
    }

  }

  getCurrUserDetails(accessToken) {
    const userId = JSON.parse(localStorage.getItem('userClaims')).userId
    let submitted_by = this.dataService.userclaims.userId
    this.dataService.getCurrentUserDeatils(userId, accessToken, submitted_by).then(data => {
      data = JSON.parse(data);
      this.tempData = data;
      this.userdData = data;
      this.userIdcontrol.setValue(data.profile.uid);
      this.lastname.setValue(data.profile.lastName);
      this.firstname.setValue(data.profile.firstName);
      this.initials.setValue(data.profile.initials[0]);
      this.employeecontrol.setValue(data.profile.employeeTypeIDM);
      this.vendorCode.setValue(data.profile.vendorCode);
      //this.email.setValue(data.profile.email);
      if (data.profile.email.includes('@bwhhotel.com')) {
        this.email.setValue('');
      }
      else { this.email.setValue(data.profile.email); }
      this.challengeQuestion1.setValue(data.profile.challengeQuestion1 ? data.profile.challengeQuestion1 : '');
      this.challengeQuestion2.setValue(data.profile.challengeQuestion2 ? data.profile.challengeQuestion2 : '');
      this.challengeQuestion3.setValue(data.profile.challengeQuestion3 ? data.profile.challengeQuestion3 : '');
      this.challengeAnswer1.setValue(data.profile.challengeAnswer1 ? data.profile.challengeAnswer1.substring(0, 8) : "");
      this.challengeAnswer2.setValue(data.profile.challengeAnswer2 ? data.profile.challengeAnswer2.substring(0, 8) : "");
      this.challengeAnswer3.setValue(data.profile.challengeAnswer3 ? data.profile.challengeAnswer3.substring(0, 8) : "");
      this.login.setValue(data.profile.login);
      this.oktaStoredAnswer1 = data.profile.challengeAnswer1;
      this.oktaStoredAnswer2 = data.profile.challengeAnswer2;
      this.oktaStoredAnswer3 = data.profile.challengeAnswer3;
    }, (error) => {
      console.log("*** error" + error);  //Error callback
    });
  }

  
  challengeAnswerPolicyCheck() {
    if (this.isChallengeAnswerPolicy) {
      this.isChallengeAnswerPolicy = false;
    }
    else {
      this.isChallengeAnswerPolicy = true;
    }
  }

  resetPassword() {
    let userDetails = {
      userName: this.login.value,
      firstName: this.firstname.value,
      lastName: this.lastname.value
    }
    localStorage.setItem("userDetails", JSON.stringify(userDetails))
    //this.router.navigateByUrl('pages/update-profile/reset-password');
    this.router.navigate(['pages/update-profile/reset-password']);
  }

  getHashValue(password: string, order: number): Promise<any> {
    return new Promise((resolve) => {
      if ((order == 1 && this.challengeAnswerTemp1 == this.oktaStoredAnswer1) || (order == 2 && this.challengeAnswerTemp2 == this.oktaStoredAnswer2) || (order == 3 && this.challengeAnswerTemp3 == this.oktaStoredAnswer3)) {
        return resolve("resolved");
      }
      this.hashPassword(password, 8, (err, hash) => {
        switch (order) {
          case 1: {
            this.challengeAnswerTemp1 = hash;
            break;
          }
          case 2: {
            this.challengeAnswerTemp2 = hash;
            break;
          }
          default: {
            this.challengeAnswerTemp3 = hash;
            break;
          }
        }

        return resolve("resolved");
      });

    });
  }

  hashPassword(password: string, rounds: number, callback: (error: Error, hash: string) => void): void {
    bcrypt.hash(password.replace(/\s/g, "").toLowerCase(), rounds, (error, hash) => {
      callback(error, hash);
    });
  }

  updateProfileInfo() {
    this.hasError = false;
    if (this.challengeQuestion1.value === '') {
      this.challengeQuestion1.markAsTouched()
      this.hasError = true;
    }
    if (this.challengeQuestion2.value === '') {
      this.challengeQuestion2.markAsTouched()
      this.hasError = true;
    }
    if (this.challengeQuestion3.value === '') {
      this.challengeQuestion3.markAsTouched()
      this.hasError = true;
    }
    if (this.challengeAnswer1.value === '') {
      this.challengeAnswer1.markAsTouched()
      this.hasError = true;
    }
    if (this.challengeAnswer2.value === '') {
      this.challengeAnswer2.markAsTouched()
      this.hasError = true;
    }
    if (this.challengeAnswer3.value === '') {
      this.challengeAnswer3.markAsTouched()
      this.hasError = true;
    }

    if (this.hasError) {
      return;
    }

    let isQuestonAndAnswer: boolean = false;
    if (this.challengeAnswer1.hasError('pattern') || this.challengeAnswer2.hasError('pattern') || this.challengeAnswer3.hasError('pattern')) {
      isQuestonAndAnswer = true;
    }
    if (!this.challengeAnswer1.valid || !this.challengeAnswer2.valid || !this.challengeAnswer3.valid) {
      isQuestonAndAnswer = true;
    }
    if (this.challengeAnswer1.value != null && this.challengeAnswer2.value != null && this.challengeAnswer3.value != null)
      if (this.challengeAnswer1.value.toUpperCase() == this.challengeAnswer2.value.toUpperCase()) {
        this.challengeAnswer2.setErrors({ unique: true })
        return;
      }
    if (this.challengeAnswer1.value.toUpperCase() == this.challengeAnswer3.value.toUpperCase()) {
      this.challengeAnswer3.setErrors({ unique: true })
      return;
    }
    if (this.challengeAnswer3.value.toUpperCase() == this.challengeAnswer2.value.toUpperCase()) {
      this.challengeAnswer3.setErrors({ unique: true })
      return;
    }
    if (this.challengeQuestion1.value == this.challengeQuestion2.value ||
      this.challengeQuestion1.value == this.challengeQuestion3.value ||
      this.challengeQuestion3.value == this.challengeQuestion2.value) {
      isQuestonAndAnswer = true;
    }

    this.challengeAnswerTemp1 = this.userdData.profile.challengeAnswer1;
    this.challengeAnswerTemp2 = this.userdData.profile.challengeAnswer2;
    this.challengeAnswerTemp3 = this.userdData.profile.challengeAnswer3;

    if (this.varType1 === "text") {
      this.challengeAnswerTemp1 = this.challengeAnswer1.value;
    }
    if (this.varType2 === "text") {
      this.challengeAnswerTemp2 = this.challengeAnswer2.value;
    }
    if (this.varType3 === "text") {
      this.challengeAnswerTemp3 = this.challengeAnswer3.value;
    }

    this.challengeAnswerTemp1 = this.challengeAnswerTemp1.trim();
    this.challengeAnswerTemp2 = this.challengeAnswerTemp2.trim();
    this.challengeAnswerTemp3 = this.challengeAnswerTemp3.trim();


    if (this.challengeAnswerTemp1.length < 3) {
      this.challengeAnswer1.setErrors({ pattern: true });
      return;
    }
    if (this.challengeAnswerTemp2.length < 3) {
      this.challengeAnswer2.setErrors({ pattern: true });
      return;
    }
    if (this.challengeAnswerTemp3.length < 3) {
      this.challengeAnswer3.setErrors({ pattern: true });
      return;
    }
  
    if (!isQuestonAndAnswer) {
      this.getHashValue(this.challengeAnswerTemp1.trim(), 1).then(() => {
        this.getHashValue(this.challengeAnswerTemp2.trim(), 2).then(() => {
          this.getHashValue(this.challengeAnswerTemp3.trim(), 3).then(() => {
            let updateProfiledata = {
              profile: {
                challengeAnswer1: this.challengeAnswerTemp1.trim(),
                challengeAnswer2: this.challengeAnswerTemp2.trim(),
                challengeAnswer3: this.challengeAnswerTemp3.trim(),
                challengeQuestion1: this.challengeQuestion1.value,
                challengeQuestion2: this.challengeQuestion2.value,
                challengeQuestion3: this.challengeQuestion3.value,
                email: this.userdData.profile.email,
                //secondEmail: this.secondaryEmail.value,
                employeeTypeIDM: this.employeecontrol.value,
                firstName: this.firstname.value,
                initials: [this.initials.value],
                uid: this.userIdcontrol.value,
                login: this.login.value,
                lastName: this.lastname.value,
                vendorCode: this.vendorCode.value
              }
            }

            let createUpdateRequest = {
              user : updateProfiledata,
              submitted_by : this.userIdcontrol.value,
              bearer_token : this.dataService.accessToken
            }
            const body = {
              "Detail":JSON.stringify(createUpdateRequest),
              "Source":"updateprofileEvent"+"/"+ this.login.value
            }
            this.dataService.postUserProfileDetails(body,this.dataService.accessToken).then((data: String) => {
              this.toastr.success("User profile updated successfully","Success",{
                timeOut: 5000,
                extendedTimeOut: 5000
              });
              setTimeout(()=> {
                this.dataService.firstLogin = false;
                this.router.navigate(['pages/home'])
              },2000)
            },(error) => {
              console.log("*** error" + JSON.stringify(error.error.message));  //Error callback
              this.toastr.error(error.error.message,"Failure",{
                timeOut: 5000,
                extendedTimeOut: 5000
              });
            });
            this.isChallengeAnswerPolicy = false;
          })
        })
      });
    } else {
      this.isChallengeAnswerPolicy = true;
    }
  }

  Cancel () {
    this.router.navigate(['pages/home'])
  }
}