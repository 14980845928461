import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { User } from './model/user';
import { map, catchError } from 'rxjs/operators';
import { TemplateProfile } from './model/templateProfile';
import { EditTemplateBody } from './model/editTemplateBody';
import { CreateTemplate } from './model/createTemplate';
import { Memberweb } from './model/memeberweb';
import { GroupsTemplate } from './model/groupsTemplate';
import { environment } from './../environments/environment';
import { TemplateSearchRequest, UserSearchRequest } from './model/idm-models';
import { RequestParameters } from './model/multiAppMenus';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};
@Injectable({
  providedIn: 'root'
})
export class DataService {
  invokeEvent: EventEmitter<any> = new EventEmitter();
  invokeBulkApply: EventEmitter<any> = new EventEmitter();
  invokeDeleteEvent: EventEmitter<any> = new EventEmitter();
  invokeDeleteConfirmation: EventEmitter<any> = new EventEmitter();
  subsvar: Subscription;
  userclaims: any;
  editServiceData: any;
  createUserType : string;
  canAccessMemberwebTab : boolean = false;
  userInfo;
  createUser: User;
  accessToken: any;
  isSuperEmployee:boolean;
  firstLogin: boolean = false;
  user: CreateTemplate = new CreateTemplate();
  editTempBody: EditTemplateBody = new EditTemplateBody();
  groupsTemplate: GroupsTemplate = new GroupsTemplate();
  userEmail: string = '';
  tempid: string;
  isCreateTemplate: boolean = false;
  isManageTemplate: boolean = false;
  isSetFromTemplate: boolean = false;
  isCancelclicked: boolean = false;
  tempName: string;
  tempSearchValue: string;
  isWorkInProgress: boolean = false;
  menuitems: Memberweb = new Memberweb();
  isWorldHotel: boolean;
  memberweb_menu_url: string;
  canShowAutoclerk: boolean = false;
  frontdeskId: string;
  canShowHomePmx: boolean;
  
  constructor(private httpClient: HttpClient) { }

  public getMultiappMenus(_reqParams: RequestParameters) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + _reqParams.token,
        'Submitted-By': _reqParams.submittedBy,
        'User-Type': _reqParams.userType,
        'User-Id': _reqParams.userId
      })
    };
    let reqUrl = `${environment.services.multi_app_url}applications/${_reqParams.appCode}/${_reqParams.menuType}`
    return this.httpClient.get<any>(reqUrl, httpOptions);
  }

  public getMultiappDetails(_reqParams: RequestParameters) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + _reqParams.token,
        'Submitted-By': _reqParams.submittedBy,
        'User-Type': _reqParams.userType,
        'User-Id': _reqParams.userId
      })
    };
    return this.httpClient.get<any>(environment.services.multi_app_url + 'applications', httpOptions);
  }

  public changeGroupAccess(body, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.multi_app_url + 'groups', body, httpOptions);
  }

  public getPropUserDetails(body, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.getPropUsersURL, body, httpOptions).toPromise();
  }

  public unlockUser(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.unlock_user_url, body, httpOptions).pipe(
      map((response: any) => {
        return response;
      }));
  }

  public activateUser(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.activate_user_url, body, httpOptions).pipe(
      map((response: any) => {
        return response;
      }));
  }

  public deactivateUser(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.suspend_user_url, body, httpOptions).pipe(
      map((response: any) => {
        return response;
      }));
  }

  public reactivateUser(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.unsuspend_user_url, body, httpOptions).pipe(
      map((response: any) => {
        return response;
      }));
  }

  public getCurrentUserDeatils(loginId, accessToken, submitted_by) {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'Submitted-By': submitted_by
      });
    return this.httpClient.get<any>(environment.services.userProfileURL + '/' + loginId, {headers}).toPromise();
  }

  public postUserDetails(body, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.userURL, body, httpOptions).toPromise();
  }

  public getUserGroupDetails(userId, accessToken, group_prefix) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<any>(environment.services.userURL + '/' + userId + '?group_prefix=' + group_prefix, httpOptions);
  }


  public postUserProfileDetails(body, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.userProfileURL, body, httpOptions).toPromise();
  }

  public sendUserSearchRequest(body: UserSearchRequest, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.search_post_url, body, httpOptions).pipe(
      map((response: any) => { return response; }));
  }

  public postPasswordChangeRequest(accessToken, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.password_change_url, body, httpOptions).pipe(
      map((response: any) => { return response; }));
  }

  public postResetUserPasswordRequest(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.reset_user_password_url, body, httpOptions).pipe(
      map((response: any) => { return response; }));
  }

  public getAffiliateOfficesforEmp(accessToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    
    return this.httpClient.get<any>(environment.services.affilate_office_url, httpOptions).toPromise();
  }

  public getAffiliateOffices(affiliateCode, accessToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<any>(environment.services.affilate_office_url + '/' + affiliateCode, httpOptions).toPromise();
  }

  public getAffiliateForProperty(propIDs: string[], accessToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    let queryString: string = "?";
    for (let i = 0; i < propIDs.length; i++) {
      queryString += "propId" + i + "=" + propIDs[i] + "&";
    }
    queryString = queryString.slice(0, -1);
    return this.httpClient.get<any>(environment.services.affilate_office_url + queryString, httpOptions).toPromise();
  }

  public getPropDetails(propIDs : string[], accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    let queryString: string = "?";
    for (let i = 0; i < propIDs.length; i++) {
      queryString += "propId" + i + "=" + propIDs[i] + "&";
    }
    queryString = queryString.slice(0, -1);
    return this.httpClient.get<any>(environment.services.resort_summary_url + queryString, httpOptions).toPromise();
  }


  public getMemberMenus(accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<any>(environment.services.memberweb_menu_url, httpOptions);
  }

  public getMemberMenusAccess(userId, body, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.memberweb_menu_url + 'user/' + userId, body, httpOptions).pipe(
      map((response: any) => { return response; }));
  }
  public getMemberOnePageUser(userId, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<any>(environment.services.memberweb_menu_url + 'user/' + userId, httpOptions);
  }
  public postTemplate(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.postURL, body, httpOptions).toPromise();
  }

  public getTemplate(body: TemplateSearchRequest, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post(environment.services.getURL, body, httpOptions).pipe(
      map((response: any) => { return response; }));
  }

  public updateTemplate(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.updateURL, body, httpOptions).pipe(map((res: any) => {
      return res;
    }))
  }

  public templatesRoles(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.templatesrolesURL, body, httpOptions).toPromise();
  }

  public deleteTemplate(body: any, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.deleteURL, body, httpOptions).toPromise();
  }

  public getVendorCodes(accessToken) {
    const httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken
    })
    };
    return this.httpClient.get<any>(environment.services.vendor_codes_url, httpOptions).toPromise();
    }

  public getVendorCodeByPropId(body: any, accessToken) {
    const httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken
    })
    };
    return this.httpClient.post<any>(environment.services.vendor_codes_url, body, httpOptions).toPromise();
    }

  public getVendorpropsByCode(vendorCd, accessToken) {
    const httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken
    })
    };
    return this.httpClient.get<any>(environment.services.vendor_codes_url + '/' + vendorCd, httpOptions).toPromise();
    }

  public getInvokeEvent(value: any){
    this.invokeEvent.emit(value);
  }

  public getMenuRestriction(userId,body: any,accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.post<any>(environment.services.multi_app_url + 'restrictions/' + userId, body, httpOptions);
  }
  
}