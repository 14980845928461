import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { OktaAuthService } from '@okta/okta-angular';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { Memberweb } from 'src/app/model/memeberweb';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { SubMenuList } from 'src/app/model/subMenuList';
import { int } from 'aws-sdk/clients/datapipeline';
import { Router } from '@angular/router';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { CreateTemplateGroupRequest } from 'src/app/model/createTemplateGroupRequest';
export interface Items {
  value: string;
  viewValue: string;
}
export interface Menu {
  "menu-id": string;
  "sub-menu-id-list": string[];
}

export interface RootObject {
  menus: Menu[];
  "updated-by": string;
}

@Component({
  selector: 'app-memberweb-privilages',
  templateUrl: './memberweb-privilages.component.html',
  styleUrls: ['./memberweb-privilages.component.css']
})
export class MemberwebPrivilagesComponent implements OnInit {
  master_checked: boolean[] = new Array();
  revenue_mgmt_checkbox_list = [];
  reservations_checkbox_list = [];
  travel_card_checkbox_list = [];
  reports_checkbox_list = [];
  quicksite_checkbox_list = [];
  solicitations_list = [];
  master_acount_list = [];
  rewards_checkbox_list = [];
  commissions_checkbox_list = [];
  bus_advantage_checkbox_list = [];
  prop_data_checkbox_list = [];
  products_checkbox_list = [];
  sub_serReq_checkbox_list = [];
  security_checkbox_list = [];
  help_checkbox_list = [];
  cust_care_checkbox_list = [];
  accessToken: string;
  headings = [];

  disableCheckboxes: boolean = false;

  memberwebMenuItems: MemberwebMenuItems[];
  menuitems: Memberweb = new Memberweb();
  tempmenuitems: Memberweb = new Memberweb();
  ediObj: any;

  constructor(public oktaAuth: OktaAuthService, private dataService: DataService, public dialog: MatDialog, private router: Router, private globalErrorHandlerService: GlobalErrorHandlerService) { }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.dataService.getInvokeEvent('Create User - '+this.dataService.createUser.profile.firstName +' '+this.dataService.createUser.profile.lastName);
    if (this.dataService.userclaims.idm_role[0] == 'OH-IDM-Role User Management - Restricted'){
      this.disableCheckboxes = true;
    }
    if (this.dataService.isSetFromTemplate == true) {
      this.dataService.getMemberMenus(this.dataService.accessToken).subscribe(
        (response) => {
          this.headings = response;
          this.memberwebMenuItems = JSON.parse(JSON.stringify(response));
          this.getUserSpecificMenus()
        },
        (error) => {
          console.log(error);
  
        }
      );

    } else {
      this.dataService.getMemberMenus(this.dataService.accessToken).subscribe(
        (response) => {
          console.log(response);
          this.headings = response
          this.memberwebMenuItems = JSON.parse(JSON.stringify(response));

          if (!this.dataService.canAccessMemberwebTab) {
            this.disableCheckboxes = true;
          }
          if (this.dataService.canAccessMemberwebTab && this.dataService.createUserType != 'PROPERTY_STAFF') {
            for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
              for (let value of this.memberwebMenuItems[i]['sub-menu-list']) {
                value.checked = true;
                this.master_checked[i] = true;
              }
              this.list_change(this.memberwebMenuItems[i].name, i);
            }
          }
        },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        }
      );
    }
  }

  getUserSpecificMenus() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    console.log('editobject', editObject);
    this.tempmenuitems.menus = editObject.menuitems.menus;
    for (let i = 0; i < this.memberwebMenuItems.length; i++) {
      for (let m = 0; m < this.tempmenuitems.menus.length; m++) {
        if (this.tempmenuitems.menus[m] !== null && this.tempmenuitems.menus[m]['menu-id'] == this.memberwebMenuItems[i]["menu-id"]) {

          let k = 0;
          for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.tempmenuitems.menus[m]['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);

              }
            }
          }
        }
      }
      this.list_change(this.memberwebMenuItems[i].name, i);
    }
  }

  Clear() {
    for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
      this.subMenuAllunChecked(i)
      this.master_change(this.headings[i].name, i)
    }
  }

  subMenuAllchecked(val: int) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: int) {
    this.master_checked[val] = false;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let user_id = this.dataService.userclaims.userId;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.memberwebMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.memberwebMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }
        this.menuitems['updated-by'] = user_id;
      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.menuitems.menus[order] = null;
    }
  }

  master_change(str: string, val: int) {
    for (let value of this.memberwebMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  async createUser() {
    let msg = '';
    let createUpdateRequest: CreateUpdateRequest = new CreateUpdateRequest();
    createUpdateRequest.user = this.dataService.createUser;
    createUpdateRequest.submitted_by = this.dataService.userclaims.userId;
    createUpdateRequest.bearer_token = this.accessToken;
    const body = { "Detail": JSON.stringify(createUpdateRequest), "Source": "createUserEvent" }
    await this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
      msg = 'success';
    }, (error) => {
      let failMeassage = ' failed to process request. Please try again later !';
      localStorage.setItem("isRequestFromEditScreen", "N");
      this.openDialog(failMeassage, "Warning")
      console.log("*** error" + error);
      msg = 'failure';
    });
    return msg;
  }

  // async assignGroupToUser() {
  //   let msg = '';
  //   let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
  //   createTemplateGroupRequest.group = this.dataService.groupsTemplate;
  //   createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
  //   createTemplateGroupRequest.bearer_token = this.accessToken;
  //   const body = { "Detail": JSON.stringify(createTemplateGroupRequest), "Source": "createUserEvent" }
  //   await this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
  //     msg = 'success';
  //   }, (error) => {
  //     let failMeassage = ' failed to process request. Please try again later !';
  //     localStorage.setItem("isRequestFromEditScreen", "N");
  //     this.openDialog(failMeassage, "Warning")
  //     console.log("*** error" + error);
  //     msg = 'failure';
  //   });
  //   return msg;
  // }

  temporaryPassword() {
    const payload = {
      "userId": this.dataService.createUser.profile.login,
      "isEmail": false,
      "submitted_by" : this.dataService.userclaims.userId,
      "bearer_token" : this.accessToken
    }
    this.dataService.postResetUserPasswordRequest(payload, this.accessToken).subscribe(
      (response) => {
        let successMessage = 'The User Id is: ' + this.dataService.createUser.profile.uid + "\n" + 'and Temporary Password is : ' + response.description.tempPassword;
        localStorage.setItem("isRequestFromEditScreen", "N");
        this.openDialog(successMessage, "User account has been created.")
      },
      (error) => {
        console.log(error);
        localStorage.setItem("isRequestFromEditScreen", "N");
        this.openDialog("Temporary passoword creation failed.", "Warning")
      }
    )
  }

  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async updateMemberwebPrivilages() {
    let message = await this.createUser();
    if (message == 'success') {
      this.dataService.isSetFromTemplate = false;
      localStorage.removeItem("editTempObject");
      if (this.dataService.canAccessMemberwebTab) {
        this.menuitems["updated-by"] = this.dataService.userclaims.userId;
        this.menuitems["bearer_token"] = this.accessToken;
        this.menuitems.menus = JSON.parse(JSON.stringify(this.menuitems.menus).replace(/null,/g, '').replace(/,null/g, ''));
        setTimeout(() => this.dataService.getMemberMenusAccess(this.dataService.createUser.profile.uid, JSON.stringify(this.menuitems), this.accessToken).subscribe(
          (response) => {

          },
          (error) => {
            let failMeassage = 'Menu Access Failed!';
            localStorage.setItem("isRequestFromEditScreen", "N");
            this.openDialog(failMeassage, "Warning")
            console.log(error)
          }
        ), 1000);
      }
      if (this.dataService.createUser.profile.email == null || this.dataService.createUser.profile.email == '' || this.dataService.createUser.profile.email.includes('@bwhhotel.com')) {
        setTimeout(() => this.temporaryPassword(), 5000);
      }
      else {
        let successMessage1 = "User account successfully created.";
        let appendValue = this.dataService.createUser.profile.login.includes('@bwhhotel.com') ? this.dataService.createUser.profile.login.replace('@bwhhotel.com', '') : this.dataService.createUser.profile.login;
        let successMessage2 = 'UserId: ' + appendValue + "\n" + " You will receive a confirmation via email with your password reset link";
        localStorage.setItem("isRequestFromEditScreen", "N");
        this.openDialog(successMessage2, successMessage1)
      }

    }
  }
  cancel() {
    this.router.navigate(['pages/create-user']);
  }

}